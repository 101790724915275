import { Utils } from 'formiojs';
import { form as template } from 'src/formio/templates/file/form';

// eslint-disable-next-line complexity
function getIconClassByMimeType(type: string) {
    switch (type) {
        case 'application/pdf':
            return 'fa fa-file-pdf-o';
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/msword':
            return 'fa fa-file-word-o';
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/msexcel':
            return 'fa fa-file-excel-o';
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return 'fa fa-file-powerpoint-o';
        case 'application/zip':
            return 'fa fa-file-archive-o';
        case 'application/json':
            return 'fa fa-file-code-o';
        case 'image/jpeg':
        case 'image/png':
        case 'image/gif':
        case 'image/bmp':
        case 'image/webp':
        case 'image/tiff':
            return 'fa fa-file-image-o';
        case 'audio/mpeg':
        case 'audio/ogg':
        case 'audio/wav':
            return 'fa fa-file-audio-o';
        case 'video/mp4':
        case 'video/ogg':
        case 'video/webm':
            return 'fa fa-file-video-o';
        case 'text/plain':
        case 'text/csv':
            return 'fa fa-file-text-o';
        default:
            return 'fa fa-file-o';
    }
}
const compiledForm = Utils.Evaluator.template(template, 'file-component');
export const formioFileComponentCustomTemplate = (ctx: any) => {
    ctx.getIconClassByMimeType = getIconClassByMimeType;
    ctx.component.image = false; // WARN Showing files as images was turned off for improve performance.

    return compiledForm(ctx);
};
