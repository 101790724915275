import _ from 'lodash';
import { computed } from 'mobx';
import { LoanProduct } from 'src/generated-api-client';
import { bpmsResourcesManagerApi, productsApi } from 'src/services/apiServices';
import { InitialFilters } from 'src/stores/ProductsStore/InitialFilters';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type ProductsFilter = {
    search?: string;
    active?: boolean;
    maxAmount?: number;
    maxTerm?: number;
    productGroupId?: string;
    campaignId?: string;
};

export class ProductsStoreClass extends BasicStore<LoanProduct> {
    itemDetailsFormKey = 'embedded:deployment:forms/bo/bo-19-loan-product.json';

    itemEditFormKey =
        'embedded:deployment:forms/bo/bo-21-edit-loan-product.json';

    itemCreateFormKey =
        'embedded:deployment:forms/bo/bo-20-create-loan-product.json';

    pager = new Pager();

    api: BasicStoreApi<LoanProduct> = {
        loadList: async (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);
            const resp = await RequestHelper.unwrapFromAxiosPromise(
                productsApi.findAllLoanProducts(
                    this.pager?.page,
                    this.pager.size,
                    InitialFilters.defaultSort,
                    this.filterCriteria.filter.maxAmount,
                    this.filterCriteria.filter.maxTerm,
                    this.filterCriteria.filter.active,
                    this.filterCriteria.filter.search,
                    options,
                ),
            );

            this.pager.setTotal(resp.totalElements || 0);

            return resp.content || [];
        },

        loadItemForm: (formKey: string, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(formKey, options),
            );
        },
        loadItem: (id: number, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.getLoanProduct(id, options),
            );
        },
        updateItem: (id, data, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.updateLoanProduct(Number(id), data, options),
            );
        },
        createItem: (data, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.createLoanProduct(data, options),
            );
        },
    };

    filterCriteria: FilterCriteria<ProductsFilter> = new FilterCriteria();

    @computed get hasFilter() {
        const { active, maxAmount, maxTerm, search, campaignId } =
            this.filterCriteria.filter;

        return (
            !_.isNil(active) ||
            !_.isNil(maxAmount) ||
            !_.isNil(maxTerm) ||
            !_.isNil(search) ||
            !_.isNil(campaignId)
        );
    }
}

export const ProductsStore = new ProductsStoreClass();
